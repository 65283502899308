<!-- 试题详情 未作答 开始答题 -->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
.page{
    position: relative;
	width: 100%;
    height: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
}
*{
	box-sizing: border-box;
}
.content{
	padding: 32px 0px 0;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
	
}
// 小题列表
::v-deep{
	.el-dialog__body{
		width: calc(100% - 40px);
	}
}
.topicList{
	width: 100%;
	height: 600px;
	position: relative;
	padding: 20px 0 80px;
	.paperName{
		text-align: center;
		line-height: 30px;
	}
	.list{
		height: calc(100% - 20px);
		overflow: auto;
		padding: 20px;
	}
	.questions{
		width: 100%;
		background-color: #FFFFFF;
		margin-bottom: 16px;
		padding: 20px;
		display: inline-block;
		overflow-x: hidden;
		border-radius: 14px;
		box-shadow: 0 0 16px #dddddd;
		.type{
			font-size: 18px;
			font-weight: bold;
			color: #222222;
			line-height: 20px;
			.statistics{
				font-weight: initial;
				font-size: 14px;
			}
		}
		.ul{
			width: 102%;
			display: inline-block;
			.item{
				float: left;
				margin-top: 16px;
				width: 40px;
				height: 40px;
				background: #E2E2E2;
				border-radius: 50%;
				margin-right: 20px;
				text-align: center;
				line-height: 40px;
				color: #FFFFFF;
				font-size: 18px;
				position: relative;
				&:hover{
					opacity: 0.8;
					cursor: pointer;
				}
				&.opt{
					background-color: $comBlue;
				}
			}
		}
	}
	.flotageBtn{
		background-color: rgba(255,255,255,0.8);
	}
}
.showAnswer{
	position: absolute;
    right: 30px;
    top: 15px;
    width: 50px;
    font-size: 22px;
	&:hover{
		opacity: 0.8;
		cursor: pointer;
	}
}
.groupOperate{
	border-radius: 50px;
	margin-top: 10px;
	overflow: hidden;
	.btn{
		border-radius: 5px;
		height: 50px;
	}
}

// 上传试题弹窗
.feeback-form {
    width: 100%;
    box-sizing: border-box;
    padding: 32px 50px;
    height: calc(100% - 64px);
    background-color: #ffffff;
    border-radius: 30px;
    margin-top: 32px;
    .img-uploader {
      min-width: 102px;
      min-height: 102px;

      ::v-deep .el-upload--text {
        min-height: 102px;
		height: auto !important;
      }
	  
      .img-list {
        width: 366px;
        display: flex;
        flex-wrap: wrap;
      }
      .img-box {
        min-height: 102px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        .el-image {
          border-radius: 10px;
        }

        .del {
          width: 24px;
          height: 24px;
          background: #fff;
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 32px;
          border-radius: 50%;
          color: #f8516d;
          @include flexBox(center);
        }
      }

      .upload-wrapper {
        width: 102px;
        height: 102px;
        background: #2ac293;
        border-radius: 10px;
        color: #fff;
        font-size: 50px;
        @include flexBox(center);
      }
    }
  }
  
.office{
	margin:0 auto;
	width: 800px;
}
.questions .li{
	cursor: pointer;
}
</style>

<template>
    <div class="page">
		<div class="flex_content_center">
			<div class="lineProgress">
				<!-- <u-line-progress :percentage="(itemPapindex/allPapLength)*100" activeColor="#01A1FD" inactiveColor="#E5F5FE" height="4">
					<div slot="default"></div>
				</u-line-progress> -->
			</div>
		</div>
		<div class="content">
			<!-- pdf展示数据--试卷 -->
			<div class="office" v-if="make_type==20">
				<vueOffice v-model="paper_url" type="pdf"></vueOffice>
			</div>
			<div class="questions" v-if="homeworkItem.syque_typ_name&&make_type==10">
				<div class="type flex_align">
					{{ papIndex + 1 | changeChinesNum }}、{{homework_list[papIndex].stexa_pap_top_title}} 
					<div class="fw-l fs-28 ml-16" v-if="homework_list[papIndex]">
						(共 {{homework_list[papIndex].question.length}}道 共 {{homework_list[papIndex].stexa_pap_top_sumscore}} 分)
					</div>
				</div>
				<div class="topic" v-if="homeworkItem.syque_typ_id!=14">
					<div class="flex_baseline"> <span class="mold">{{homeworkItem.syque_typ_name}} </span> <div class="val">  <em class="richinline flex_1" v-html="homeworkItem.stexa_que_title"></em> <span>({{homeworkItem.stexa_que_score}}分)</span></div></div>
					<img class="selectimg" v-if="homeworkItem.stexa_que_title_image" :src="homeworkItem.stexa_que_title_image" alt="">
					<!-- <el-image v-if="homeworkItem.stexa_que_title_image" style="width: 200px; height: 100px" :src="homeworkItem.stexa_que_title_image" :preview-src-list="[homeworkItem.stexa_que_title_image]"></el-image> -->
				</div>
				<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
				<!-- 单选和判断 -->
				<div v-if="homeworkItem.syque_typ_id==1||homeworkItem.syque_typ_id==5">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index+'_'+topicIndex">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
						<div class="li" :class="getopt(item.stexa_que_ite_code,homeworkItem.stexa_que_stuanswer)?'opt':''" @click="clickPap(index,item.stexa_que_ite_code)">
							<div class="flex">
								<div class="number">{{item.stexa_que_ite_code}}.</div>
								<div class="va">
									<div class="richblock" v-html="item.stexa_que_ite_title"></div>
								</div>
							</div>
							<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
							<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 200px; height: 100px" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
						</div>
					</div>
				</div>
				<!-- 多选 -->
				<div v-if="homeworkItem.syque_typ_id==2">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index+'_'+topicIndex">
						<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
						<div class="li" :class="getopt(item.stexa_que_ite_code,homeworkItem.stexa_que_stuanswer)?'opt':''" @click="clickPap(index,item.stexa_que_ite_code)">
							<div class="flex">
								<div class="number">{{item.stexa_que_ite_code}}.</div>
								<div class="val">
									<div class="richblock" v-html="item.stexa_que_ite_title"></div>
								</div>
							</div>
							<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
							<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 200px; height: 100px" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
						</div>
					</div>
				</div>
				<!-- 单项填空 -->
				<div v-if="homeworkItem.syque_typ_id==3">
					<div v-for="(item,index) in homeworkItem.question_item" :key="index+'_'+topicIndex">
						<try-editor :key="homeworkItem.stexa_que_id+'_'+index" v-model="homeworkItem.stexa_que_stuanswer[index]" placeholder="点击输入"></try-editor>
					</div>
					<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
					<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
				</div>
				<!-- 多项填空 -->
				<div v-if="homeworkItem.syque_typ_id==4">
					<div class="caption c-8">请依次将填空题答案输入到下框中</div>
					<div v-for="(item,index) in homeworkItem.question_item" :key="index+'_'+topicIndex">
        				<try-editor :key="homeworkItem.stexa_que_id" v-model="homeworkItem.stexa_que_stuanswer[index]" placeholder="点击输入"></try-editor>
					</div>
					<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
					<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
				</div>
				<!-- 8问答 -->
				<div v-if="homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8">
					<try-editor :key="homeworkItem.stexa_que_id+'_'+topicIndex" v-model="homeworkItem.stexa_que_stuanswer[0]" placeholder="点击输入"></try-editor>
					<img class="selectimg" v-if="homeworkItem.stexa_que_stuanswer_image" :src="homeworkItem.stexa_que_stuanswer_image" alt="">
					<!-- <el-image v-if="homeworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="homeworkItem.stexa_que_stuanswer_image" :preview-src-list="[homeworkItem.stexa_que_stuanswer_image]"></el-image> -->
				</div>
				<!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 组合题 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
				<div v-if="homeworkItem.syque_typ_id==14">
					<div class="topic">
						<div class="val">
							<div class="richblock" v-html="homeworkItem.stexa_que_title"></div>
						</div>
						<img class="selectimg" v-if="homeworkItem.stexa_que_title_image" :src="homeworkItem.stexa_que_title_image" alt="">
						<!-- <el-image v-if="homeworkItem.stexa_que_title_image" style="width: 200px; height: 100px;" :src="homeworkItem.stexa_que_title_image" :preview-src-list="[homeworkItem.stexa_que_title_image]"></el-image> -->
					</div>
					<div class="p-0-32">
						<div class="topic">
							<div class="val"><em class="richinline" v-html="groupworkItem.stexa_que_title"></em>
								<span class="fs-28 fw-l">
								（{{groupworkItem.syque_typ_name}}）
								</span>
							</div>
							<img class="selectimg" v-if="groupworkItem.stexa_que_title_image" :src="groupworkItem.stexa_que_title_image" alt="">
							<!-- <el-image v-if="groupworkItem.stexa_que_title_image" style="width: 200px; height: 100px;" :src="groupworkItem.stexa_que_title_image" :preview-src-list="[groupworkItem.stexa_que_title_image]"></el-image> -->
						</div>
						
						<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
						<!-- 单选和判断 -->
						<div v-if="groupworkItem.syque_typ_id==1||groupworkItem.syque_typ_id==5">
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index+'_'+topicIndex+'_'+grouTopicIndex">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="getopt(item.stexa_que_ite_code,groupworkItem.stexa_que_stuanswer)?'opt':''" @click="clickMinPap(index,item.stexa_que_ite_code)">
									<div class="flex">
										<div class="number">{{item.stexa_que_ite_code}}.</div>
										<div class="val">
											<div class="richinline" v-html="item.stexa_que_ite_title"></div>
										</div>
									</div>
									<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
									<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 200px; height: 100px;" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
								</div>
							</div>
						</div>
						<!-- 多选 -->
						<div v-if="groupworkItem.syque_typ_id==2">
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index+'_'+topicIndex+'_'+grouTopicIndex">
								<!-- 判断单题的答案下标位置是否等于当前题目的选项值-->
								<div class="li" :class="getopt(item.stexa_que_ite_code,groupworkItem.stexa_que_stuanswer)?'opt':''" @click="clickMinPap(index,item.stexa_que_ite_code)">
									<div class="flex">
										<div class="number">{{item.stexa_que_ite_code}}.</div>
										<div class="val">
											<div class="richinline" v-html="item.stexa_que_ite_title"></div>
										</div>
									</div>
									<img class="selectimg" v-if="item.stexa_que_ite_image" :src="item.stexa_que_ite_image" alt="">
									<!-- <el-image v-if="item.stexa_que_ite_image" style="width: 200px; height: 100px" :src="item.stexa_que_ite_image" :preview-src-list="[item.stexa_que_ite_image]"></el-image> -->
								</div>
							</div>
						</div>
						<!-- 单项填空 -->
						<div v-if="groupworkItem.syque_typ_id==3">
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index+'_'+topicIndex+'_'+grouTopicIndex">
								<try-editor :key="groupworkItem.stexa_que_id+'_'+index+'_'+topicIndex+'_'+grouTopicIndex" v-model="groupworkItem.stexa_que_stuanswer[index]" placeholder="点击输入"></try-editor>
							</div>
							<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
							<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
						</div>
						<!-- 多项填空 -->
						<div v-if="groupworkItem.syque_typ_id==4">
							<div class="caption c-8">请依次将填空题答案输入到下框中</div>
							<div v-for="(item,index) in groupworkItem.question_child_item" :key="index+'_'+topicIndex+'_'+grouTopicIndex">
								<try-editor :key="groupworkItem.stexa_que_id+'_'+index+'_'+topicIndex+'_'+grouTopicIndex" v-model="groupworkItem.stexa_que_stuanswer[index]" placeholder="点击输入"></try-editor>
							</div>
							<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
							<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
						</div>
						<!--7计算 8问答 -->
						<div v-if="groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8">
							<try-editor :key="groupworkItem.stexa_que_id" v-model="groupworkItem.stexa_que_stuanswer[0]" placeholder="点击输入"></try-editor>
							<img class="selectimg" v-if="groupworkItem.stexa_que_stuanswer_image" :src="groupworkItem.stexa_que_stuanswer_image" alt="">
							<!-- <el-image v-if="groupworkItem.stexa_que_stuanswer_image" style="width: 200px; height: 100px;" :src="groupworkItem.stexa_que_stuanswer_image" :preview-src-list="[groupworkItem.stexa_que_stuanswer_image]"></el-image> -->
						</div>
						<div class="flex_bet_align groupOperate">
							<div class="combtn3 btn" v-if="grouTopicIndex>0" @click="grouPriorPap">
								<div class="icon iconjiantouzuo1 mr-10"></div>
								上一题
							</div>
							<div class="combtn4 btn" v-else>
								<div class="icon iconjiantouzuo1 mr-10"></div>
								上一题
							</div>
							
							<div class="combtn4 btn" v-if="itemPapindex==allPapLength&&grouTopicIndex==homework_list[papIndex].question[topicIndex].question_item.length-1">
								最后一题了
							</div>
							<div class="combtn3 btn" @click="grouNextPap" v-else>
								下一题
								<div class="icon iconjiantouyou ml-10"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flotageBtn bg-c-f" v-if="make_type==10">
			<div class="combtn3 mr-24" v-if="itemPapindex>1" @click="priorPap">上一题</div>
			<div class="combtn4 mr-24" v-else>上一题</div>
			<div class="combtn5" v-if="itemPapindex==allPapLength" @click="nextPap">提交试卷</div>
			<div class="combtn3" v-else @click="nextPap">下一题</div>
			<div class="combtn3 showAnswer" @click="showAnswer=true" v-if="itemPapindex!=allPapLength">
				<div class="iconfont">&#xe681;</div>
			</div>
		</div>
		
		<div class="flotageBtn bg-c-f" v-if="make_type==20">
			<div></div>
			<div class="combtn5" @click="pdfsubmit">提交试卷</div>
		</div>
		<!-- 显示答题进度 -->
		<el-dialog title="试卷目录" :visible.sync="showAnswer" width="50%">
			<div class="topicList">
				<div class="paperName">请认真检查以下答题情况，灰色是未作答的题</div>
				<div class="list">
					<div class="questions" v-for="(item,index) in homework_list" :key="index">
						<div class="type flex_content_wrap">
							<div>{{ index + 1 | changeChinesNum }}、{{item.stexa_pap_top_title}} </div> 
							<div class="statistics c-8">（共{{item.question.length}}道，共{{item.stexa_pap_top_sumscore}}分）</div>
						</div>
						<div class="ul">
							<div v-for="(item2,index2) in item.question" :key="index2">
								<div class="item" :class="getAnswer(item2)?'opt':''" @click="clickQuestion(index,index2)">{{index2+1}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flotageBtn  flex_content_center"> <div class="combtn3" @click="submitPaper">提交试卷</div> </div>
			</div>
        </el-dialog>
		<!-- 显示答题进度 -->
		<el-dialog title="上传试卷" :visible.sync="shouPhotograph" width="50%" :close-on-press-escape="false" :close-on-click-modal="false" @close="closePhotograph">
			<div class="feeback-form">
				<el-form ref="formData" :model="formData" label-width="68px" :rules="rules" >
					<el-form-item label="上传图片" prop="plfee_image">
					<el-upload class="img-uploader" ref="imgUploader" :action="$upload.imgAction" :headers="$upload.header" :show-file-list="false" :accept="$upload.imgAccept" :before-upload="$beforImgUpload" :on-success="(res) => {return uploadImgSuccess(res); }"
						:on-exceed=" () => { $message.warning('上传文件数量超出限制，最多上传4个'); }"
						multiple
						:limit="4"
					>
						<div class="img-list">
						<template v-if="formData.plfee_image.length">
							<div
							class="img-box"
							v-for="(item, index) in formData.plfee_image"
							:key="index"
							>
							<el-image
								style="width: 102px; height: 102px"
								:src="formatfile(item)"
								:preview-src-list="[formatfile(item)]"
							/>
							<i class="del iconfont" @click.stop="delImg(index)"
								>&#xe63e;</i
							>
							</div>
						</template>
						<div
							class="upload-wrapper"
							v-if="formData.plfee_image.length < 9"
						>
							<i class="iconfont">&#xe63d;</i>
						</div>
						</div>
					</el-upload>
					</el-form-item>
					<el-form-item>
					<el-button
						type="custom_primary"
						size="small"
						@click="handleSubmit"
						v-loading="btnload"
						>确 定</el-button
					>
					</el-form-item>
				</el-form>
			</div>
        </el-dialog>
	</div>	
</template>
<script>
var intervals;
import { stuexamination_start_answer,stuexamination_submit_paper,stuexamination_do_answer,stuexamination_submit_images } from "@api/home"
import { formatFile } from "@utils";
import vueOffice from "@comp/vueOffice"
export default {
    components: {
        vueOffice
    },
	computed: {
		formatfile() {
			return function (url) {
				return formatFile(url);
			};
		},
	},
    name: 'examinationDetailsItem',
    data() {
		var validateimage = (rule, value, callback) => {
			if (this.formData.plfee_image.length<1) {
				return callback(new Error('请上传试卷'));
			}else{
				callback();
			}
		};
        return {
			//是否提交提示
			showpopup:false,
			//显示小题列表
			showAnswer:false,
			//试卷id
			stexa_id:0,
			//所有试题
			homework_list:[],
			//单题内容
			homeworkItem:{},
			//组合题单题内容
			groupworkItem:{},
			//题型下标
			papIndex:0,
			//题目下标
			topicIndex:0,
			//组合题小题下标
			grouTopicIndex:0,
			//总题数量
			allPapLength:0,
			//当前第几题
			itemPapindex:0,
			//答题结束时间
			endtime:0,
			//上传试题弹窗
			shouPhotograph:false,
			// 试题窗口
			formData: {
				plfee_image: [],
			},
			btnload: false,
			rules: {
				plfee_image: [
				{ validator: validateimage, trigger: 'change' },
				],
			},
			//pdf的地址
			paper_url:"",
			//题目类型 10组卷题  20pdf题
			make_type:10,
			
        }
    },
    created() {
        this.endtime=this.$route.params.endtime
        this.stexa_id=this.$route.params.id
        this.paper_url=this.$route.params.paper_url
        this.make_type=this.$route.params.make_type
		if(this.$route.params.make_type==10){
       		this.getData();
		}
		//启动答题提交时间
		this.autoSubmit()
    },
    methods: {
        //获取科目
		async getData(){
			//关闭定时器
			clearInterval(intervals);
            let { data } = await stuexamination_start_answer({stexa_id:this.stexa_id});
			try{	
				//渲染列表
				this.homework_list=data
				//渲染单题
				this.homeworkItem=data[0].question[0]
				//判断第一类是否为组合提
				if(data[0].question[0].syque_typ_id==14){
					this.setGroupworkItem(data[0].question[0].question_item[0])
				}
			}catch(e){
				this.$message.error('试题数据错误');
			}
			this.getPapNumber()
            this.$forceUpdate();
		},
		// 时间结束自动提交试卷
		autoSubmit(){
			let nowTime;
			intervals = setInterval(()=>{
				nowTime = (Date.parse(new Date()) / 1000);
				//已经结束了
				if (parseInt(nowTime)>=parseInt(this.endtime)) {
					//提交试卷
					this.submitPaper()
				}
			}, 1000);
		},
		//获取当前试题进度
		getPapNumber(){
			//总题数
			let allPapLength=0
			//当前题数
			let itemPapindex=0
			this.homework_list.map((item,index)=>{
				//获取总题数
				item.question.map((item2,index2)=>{
					allPapLength++
				})
				//获取当前第几题
				// 大类判断是否大于当前遍历的index 
				// 大于累加前面所有大类里面的数量
				if(this.papIndex>index){
					// 超过累加
					item.question.map((item2,index2)=>{
						itemPapindex++
					})
				}
				//等于当前题累加当前小题前面的数量
				if(this.papIndex==index){
					// 超过累加
					item.question.map((item2,index2)=>{
						if(this.topicIndex>=index2){
							itemPapindex++
						}
					})
				}
			})
			this.allPapLength=allPapLength
			this.itemPapindex=itemPapindex
		},
		//弹出是否退出
		navigateBack(){
			this.showpopup=true
		},
		//提交试卷
		submitPaper(){
			clearInterval(intervals);
			// 处理点击的试题列表直接点击提交 当前题被遗漏未提交
			if(this.homeworkItem.syque_typ_id!=14){
				// 判断最后一题普通类型提交
				this.submitItem(this.homeworkItem,()=>{
					this.listSubmit()
				});
			}else{
				// 提交组合题答案，并展示出所有题型类表
				this.submitItem(this.groupworkItem,()=>{
					this.listSubmit()
				});
			}
		},
		//点击试题列表直接提交按钮
		async listSubmit(){
			let res = await stuexamination_submit_paper({stexa_id:this.stexa_id});
			if(res.status==200){
				this.$alert('试卷已提交,上传试题', '考试结束', {
					confirmButtonText: '确定',
					callback: action => {
						this.shouPhotograph=true;
					}
				});
			}else if(res.status==203){
				this.showAnswer=false
				this.shouPhotograph=true;
			}else{
				this.$message.error('提交失败，请重试');
			}
		},
		//试题列表 点击试题类表里面某个值
		clickQuestion(index,index2){
			let workItem={}
			//判断当前的题型
			if(this.homework_list[this.papIndex].question[this.topicIndex].syque_typ_id!=14){
				workItem=this.homeworkItem
			}else{
				workItem=this.groupworkItem
			}
			//提交答案
			this.submitItem(workItem,()=>{
				// 切换之前提交刚刚的表单
				// 提交过后再切换下一题
				this.showAnswer=false
				this.topicIndex=index2
				this.papIndex=index
				this.grouTopicIndex=0
				this.homeworkItem=this.homework_list[index].question[index2]
				// 接下来的提是否是组合提
				if(this.homework_list[index].question[index2].syque_typ_id==14){
					// 清空选项框里面的数据
					this.setGroupworkItem(this.homework_list[index].question[index2].question_item[0])
				}
				this.getPapNumber()
			});
		},
		//点击单选题
		clickPap(index,val){
			let workItem=this.homeworkItem
			if(workItem.syque_typ_id==1||workItem.syque_typ_id==5){
				//单选题或判断题
				workItem.stexa_que_stuanswer=[val]
			}else if(workItem.syque_typ_id==2){
				//多选题
				if(this.getopt(val,workItem.stexa_que_stuanswer)){
					workItem.stexa_que_stuanswer.splice(workItem.stexa_que_stuanswer.indexOf(val),1)
				}else{
					workItem.stexa_que_stuanswer.push(val)
				}
			}
			this.homework_list[this.papIndex].question[this.topicIndex]=workItem
			this.homeworkIte=workItem
			this.$forceUpdate();
			
		},
		//组合题选中
		clickMinPap(index,val){
			let workItem=this.groupworkItem
			if(workItem.syque_typ_id==1||workItem.syque_typ_id==5){
				//单选题或判断题
				workItem.stexa_que_stuanswer=[val]
			}else if(workItem.syque_typ_id==2){
				//多选题
				if(this.getopt(val,workItem.stexa_que_stuanswer)){
					workItem.stexa_que_stuanswer.splice(workItem.stexa_que_stuanswer.indexOf(val),1)
				}else{
					workItem.stexa_que_stuanswer.push(val)
				}
			}
			this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex]=workItem
			// 清空选项框里面的数据
			this.setGroupworkItem(workItem)
			this.$forceUpdate();
			
		},
		//大题切换上一题
		priorPap(){
			//提交答案
			this.submitItem(this.homeworkItem,()=>{
				// 切换上一题
				this.changePrior()
			});
		},
		//大题切换下一题
		nextPap(){
			//提交答案
			this.submitItem(this.homeworkItem,()=>{
				// 切换下一题
				this.changeNext()
			});
		},	
		//小题切换上一题
		grouPriorPap(){
			//提交答案
			this.submitItem(this.groupworkItem,()=>{
				// 切换上一题
				this.changeGrouPrior()
			});
		},
		//小题切换下一题
		grouNextPap(){
			//提交答案
			this.submitItem(this.groupworkItem,()=>{
				// 切换下一题
				this.changeGrouNext()
			});
		},
		//页面大题切换上一题
		changePrior(){
			//小题下标重置
			this.grouTopicIndex=0
			//判断当前大类里面是否为还有上一题  true有上一题
			if(this.topicIndex>0){
				//回到上一小题
				this.topicIndex=this.topicIndex-1
				this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
				// 清空选项框里面的数据
				this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
				this.getPapNumber()
			}else{
				// 判断是否还有上一大类 true还有上一大类
				if(this.papIndex>0){
					//题类型+1
					this.papIndex=this.papIndex-1
					//大题下标跳转到上一个类型的最后一个
					this.topicIndex=this.homework_list[this.papIndex].question.length-1
					//已经是第一个题了，判断上一个大类题型  不是组合提的情况下
					if(this.homework_list[this.papIndex].question[this.topicIndex].syque_typ_id!=14){
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
					}else{
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
						// 清空选项框里面的数据
						this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
					}
					this.getPapNumber()	
				}else{
					//组合提填充
					this.$message({
						message: "没有上一题了",
						type: 'warning'
					});
				}
			}
		},
		//页面大题切换下一题
		changeNext(){
			//小题下标重置
			this.grouTopicIndex=0
			// 判断大类里面的大题后面是否还有小题
			if(this.homework_list[this.papIndex].question.length>this.topicIndex+1){
				//还有下一题
				this.topicIndex=this.topicIndex+1
				this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])	
				// 清空选项框里面的数据
				this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
				this.getPapNumber()
			}else{
				// 切换下一个题型
				//判断下一个类型是否也是最后一个 true还有下一个类型
				if(this.homework_list.length>this.papIndex+1){
					//题类型+1
					this.papIndex=this.papIndex+1
					//大题下标重置
					this.topicIndex=0
					//判断是否是组合提
					if(this.homework_list[this.papIndex].question[0].syque_typ_id!=14){
						//替换值
						this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
					}else{
						//组合提填充
						this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
						// 清空选项框里面的数据
						this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
					}
					this.getPapNumber()
				}else{
					//最后一题
					this.showAnswer=true
				}
			}
		},
		
		//页面小题切换上一题
		changeGrouPrior(){
			//小题是否是第一个  true还有上一小题
			if(this.grouTopicIndex>0){
				//回到上一小题
				// 切换小题
				//还有上一题
				this.grouTopicIndex=this.grouTopicIndex-1
					// 清空选项框里面的数据
					this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
			}else{
				// 判断大题是否是第一个 true还有上一个大题
				if(this.topicIndex>0){
					//回到上一小题
					//大题累加
					this.topicIndex=this.topicIndex-1
					//小题下标重置
					this.grouTopicIndex=0
					//替换值
					this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
					// 清空选项框里面的数据
					this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
					this.getPapNumber()
				}else{
					// 该类型已经是第一个第一题
					//判断是否是第一个类型 true 还有上一个类型
					if(this.papIndex>0){
						//题类型+1
						this.papIndex=this.papIndex-1
						//大题下标重置
						this.topicIndex=this.homework_list[this.papIndex].question.length-1
						//小题下标重置
						this.grouTopicIndex=0
						//判断是否是组合提 true不是组合提
						if(this.homework_list[this.papIndex+1].question[this.topicIndex].syque_typ_id!=14){
							//替换值
							this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
						}else{
							//组合题填充
							//替换值
							this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
							// 清空选项框里面的数据
							this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
						}
						this.getPapNumber()
					}else{
						this.$message({
							message: "没有上一题了",
							type: 'warning'
						});
					}
				}
			}
		},
		//页面小题切换下一题
		changeGrouNext(){
			// 判断组合提里面的大题里面的小题是否还有下一题 true还有下一小题
			if(this.homework_list[this.papIndex].question[this.topicIndex].question_item.length>this.grouTopicIndex+1){
				// 切换小题
				//还有下一题
				this.grouTopicIndex=this.grouTopicIndex+1
				// 清空选项框里面的数据
				this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
			}else{
				// 切换大题
				//判断类型下面的大题不是最后一题 true还有下大题
				if(this.homework_list[this.papIndex].question.length>this.topicIndex+1){
					//大题累加
					this.topicIndex=this.topicIndex+1
					//小题下标重置
					this.grouTopicIndex=0
					//替换值
					this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
					// 清空选项框里面的数据
					this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
					this.getPapNumber()
				}else{
					//是否最后一个类型 true还有下一类
					if(this.homework_list.length>this.papIndex+1){
						//题类型+1
						this.papIndex=this.papIndex+1
						//大题下标重置
						this.topicIndex=0
						//小题下标重置
						this.grouTopicIndex=0
						//判断是否是组合提 true不是组合提
						if(this.homework_list[this.papIndex].question[0].syque_typ_id!=14){
							//还有下一题 
							//替换值
							this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
						}else{
							//组合题填充
							//替换值
							this.setHomeworkItem(this.homework_list[this.papIndex].question[this.topicIndex])
							// 清空选项框里面的数据
							this.setGroupworkItem(this.homework_list[this.papIndex].question[this.topicIndex].question_item[this.grouTopicIndex])
						}
						this.getPapNumber()
					}else{
						this.$message({
							message: "已经是该类型最后一题",
							type: 'warning'
						});
					}
				}
			}
		},
		//清空单题的答案，切换下一题的时候input框不带刷新功能
		setHomeworkItem(homeworkItem){
			if(homeworkItem.stexa_que_stuanswer&&homeworkItem.stexa_que_stuanswer.length<1&&(homeworkItem.syque_typ_id==3||homeworkItem.syque_typ_id==4||homeworkItem.syque_typ_id==7||homeworkItem.syque_typ_id==8)){
				homeworkItem.question_item.map((iten,index)=>{
					homeworkItem.stexa_que_stuanswer[index]=homeworkItem.stexa_que_stuanswer[index]?homeworkItem.stexa_que_stuanswer[index]:""
				})
			}
			this.homeworkItem=homeworkItem
		},
		//清空单题的答案，切换下一题的时候input框不带刷新功能
		setGroupworkItem(groupworkItem){
			if(groupworkItem&&groupworkItem.stexa_que_stuanswer&&groupworkItem.stexa_que_stuanswer.length<1&&(groupworkItem.syque_typ_id==3||groupworkItem.syque_typ_id==4||groupworkItem.syque_typ_id==7||groupworkItem.syque_typ_id==8)){
				groupworkItem.question_child_item.map((iten,index)=>{
					groupworkItem.stexa_que_stuanswer[index]=groupworkItem.stexa_que_stuanswer[index]?groupworkItem.stexa_que_stuanswer[index]:""
				})
			}
			this.groupworkItem=groupworkItem
		},
		//提交答题
		async submitItem(homework,fn){
				// fn && typeof fn == 'function' && fn()
				// return false
			//有数组
			let stexa_que_stuanswer=homework.stexa_que_stuanswer
			let stexa_que_stuanswer_image=homework.stexa_que_stuanswer_image?homework.stexa_que_stuanswer_image:""
			let obj={
				stexa_id:this.stexa_id,
				stexa_que_id:homework.stexa_que_id,
				stexa_que_stuanswer:stexa_que_stuanswer,
				stexa_que_stuanswer_image:stexa_que_stuanswer_image,
			}
			let res = await stuexamination_do_answer(obj);
			if(res.status==200){
				fn && typeof fn == 'function' && fn()
			}else{
				if(res&&parseInt(res.status)==203){
					this.$alert('当前考试已结束,上传试题', '提示', {
						confirmButtonText: '确定',
						type: 'warning'
					}).then(() => {
						this.shouPhotograph=true;
					})
				}else{
					this.$message.error('提交失败，请重试');
				}
			}
		},
		//判断试题是否选中 answer答案  list
		getopt(answer,list){
			if(answer&&list&&list.length>0){
				return list.includes(answer)
			}else{
				return false
			}
		},
		//返回是否答题
		getAnswer(item){
			let pass=true
			if(item.syque_typ_id==14){
				item.question_item.map(item2=>{
					let stexa_que_stuanswer=item2.stexa_que_stuanswer
					let stexa_que_stuanswer_image=item2.stexa_que_stuanswer_image
					if(stexa_que_stuanswer&&stexa_que_stuanswer.length>0&&!stexa_que_stuanswer_image){
						stexa_que_stuanswer.map(item=>{
							if(item==""||item.length==0){
								pass=false
							}
						})
					}else if(stexa_que_stuanswer_image&&stexa_que_stuanswer_image.length>0){
						//有图片
						pass = true
					}else{
						pass=false
					}
				})
			}else{
				let stexa_que_stuanswer=item.stexa_que_stuanswer
				let stexa_que_stuanswer_image=item.stexa_que_stuanswer_image
				if(stexa_que_stuanswer&&stexa_que_stuanswer.length>0&&!stexa_que_stuanswer_image){
					stexa_que_stuanswer.map(item2=>{
						if(item2==""||item2.length==0){
							pass=false
						}
					})
				}else if(stexa_que_stuanswer_image&&stexa_que_stuanswer_image.length>0){
					//有图片
					pass = true
				}else{
					pass=false
				}
			}
			return pass
		},	
		
		/**
		 * 图片上传成功
		 */
		uploadImgSuccess({ data, el }) {
			if (this.$isEmpty(data)) return this.$message.error(msg);
			this.formData.plfee_image.push(data.address);
			this.$forceUpdate();
		},
		/** 删除图组图片 */
		delImg(index) {
			this.$refs.imgUploader.uploadFiles.splice(index, 1);
			let imgs = [...this.formData.plfee_image];
			imgs.splice(index, 1);
			this.formData = {
				...this.formData,
				plfee_image: [...imgs],
			};
		},

		/** 提交试题 */
		handleSubmit() {
			this.$refs.formData.validate(async (valid) => {
				if (valid) {
					this.btnload = true;
					let params = {
						stexa_id: this.stexa_id,
						stexa_images: this.formData.plfee_image.join(","),
					};
					let res = await stuexamination_submit_images(params);
					this.btnload = false;
					if (res) {
						this.shouPhotograph=false;
						this.$message.success("提交成功");
						this.$refs.formData.resetFields();
						this.goexamIndex();
					}
				}
			});
		},
		pdfsubmit(){
			this.$alert('当前考试已结束,上传试题', '提示', {
				confirmButtonText: '确定',
				type: 'warning'
			}).then(async () => {
				let res = await stuexamination_submit_paper({stexa_id:this.stexa_id})
				if(res.status==200){
					this.shouPhotograph=true;
				}
			})
		},
		//取消上传二次弹窗
		closePhotograph(){
			this.goexamIndex();
			return false
			this.$confirm('确定取消上传试卷？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
				this.goexamIndex();
			}).catch(() => {
				this.shouPhotograph=true;
			 });
		},
		// 返回试卷列表
		goexamIndex(){
            this.$router.push({
				name:"EXAM_INDEX",
				params: {}
			})
		},
    },
}
</script>